<template>
  <LightboxBase
    @closed="closeMapLightbox()"
    @accessibility="accessibilityTextTrigger()"
    ref="lightbox"
    :showLightbox="showLightbox"
    :accessibilityMode="accessibilityMode"
    :hasAccessibleText="
      pageData.accessibility_text ? pageData.accessibility_text : ''
    "
    v-if="mapLightbox"
  >
  
    <LightboxContentLayout
      :ariaDescription="'accessibilityDescriptionMap'"
      :accessibilityTextFlag="accessibilityTextFlag"
      :accessibilityText="
        pageData.accessibility_text ? pageData.accessibility_text : ''
      "
    >
    
        <Map
          :aria-label="
            pageData.accessibility_text ? pageData.accessibility_text : ''
          "
          :pageData="pageData"
          tabindex="-1"
          class="map-lightbox"
          :accessibilityZoom="true"
          mapLightbox
          data-testid="accessibility-map"
        />
      
    </LightboxContentLayout>
   
  </LightboxBase>
</template>

<script>
import LightboxBase from "../lightbox/LightboxBase.vue";
import LightboxContentLayout from "../lightbox/LightboxContentLayout.vue";
import Map from "../esrimap/Map.vue";

export default {
  name: "MapLightbox",
  components: {
    LightboxBase,
    LightboxContentLayout,
    Map,
  },
  props: {
    pageData: Object,
    showLightbox: Boolean,
    accessibilityMode: Boolean,
    mapLightbox: Boolean,
  },
  data: () => ({
    accessibilityTextFlag: false,
  }),
  methods: {
    closeMapLightbox() {
      this.$emit("closed");
    },
    accessibilityTextTrigger() {
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
  },
};
</script>

<style scoped>
.map-lightbox {
  height: 80%;
  width: 100%;
  position: relative;
}

</style>