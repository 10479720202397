<template>
    <v-container>
      <v-row>
        <div v-for="(button, i) in data" :key="i" class="ma-1 full-width-div" :data-testid="'buttonlink-item' + i">
          <v-btn class="button-link-style" :style="buttonLinkStyle" v-if="button.button_type[0].type === 'document_link'"  target="_blank" :aria-label="button.accessibility_text" :href="button.button_type[0].value.file">
            <v-icon left >{{button.icon}}</v-icon>
            <span>{{button.title}}</span>
          </v-btn>
          <v-btn class="button-link-style" :style="buttonLinkStyle" v-else-if="button.button_type[0].type === 'other_link'"  target="_blank" :aria-label="button.accessibility_text" :href="button.button_type[0].value">
            <v-icon left  >{{button.icon}}</v-icon>
            <span  >{{button.title}} </span>
          </v-btn>
          <v-btn class="button-link-style" :style="buttonLinkStyle" v-else-if="button.button_type[0].type === 'page_link'"  :aria-label="button.accessibility_text"  :to="getPageLink(button)" @click="resetCurrentPopup">
            <v-icon left >{{button.icon}}</v-icon>
           <span> {{button.title}} </span>
          </v-btn>
          <v-btn class="button-link-style" :style="buttonLinkStyle" v-else-if="button.button_type[0].type === 'lightbox'"  :aria-label="button.accessibility_text"  @click="() => openLightbox(button)">
            <v-icon left >{{button.icon}}</v-icon>
           <span > {{button.title}} </span>
          </v-btn>
        </div>
      </v-row>
      <div class="child-lightbox-container" v-if="showLightbox">
      <v-scale-transition origin="center center">
        <LightboxGallery
          :content="lightboxContent"
          :startIndex="lightboxStartIndex"
          @closed="closeLightbox()"
          :showLightbox="showLightbox"
        />
      </v-scale-transition>
    </div>
    </v-container>
</template>
<script>
export default {
  name: "ButtonLinkRowBlock",
  props: ["data"],
  data: () => ({
    lightboxContent: null,
    lightboxStartIndex: 0,
    showLightbox: false,
    hasDarkTheme: false
  }),
  mounted() {
      const buttonLink = document.getElementsByClassName("button-link-style")[0]
      const buttonLinkClassName = buttonLink.className
      console.log("buttonLinkClassName", buttonLinkClassName)

      const darkTheme = buttonLinkClassName.includes("theme--dark")
      this.hasDarkTheme = darkTheme ? true : false
  },
  computed: {
    buttonLinkStyle() {
      if(!this.hasDarkTheme) {
        return `
          background-color: ${this.$vuetify.theme.themes.light.secondary};
          color: white;
        `
      }
      return ''
    }
  },
  methods: {
    getPageLink(button){
      const backendUrl = button.button_type[0].value.meta.html_url
      return new URL(backendUrl).pathname
    },
    resetCurrentPopup() {
      this.$store.dispatch("wagtail/setCurrentPopup", undefined)
    },

    openLightbox(button) {
      console.log("open Lightbox from button link")
      this.lightboxContent = button.button_type[0].value
      this.lightboxStartIndex = 0
      this.showLightbox = true
    },
    
    closeLightbox() {
      this.showLightbox = false
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  white-space: normal;
  min-height: 36px;
  height: fit-content !important;
  max-height: fit-content !important;  
  padding: 5px 16px !important;
  width: 100%;
}

.full-width-row {
  width: 100%;
}

.full-width-div {
  width: 100%;
}

.child-lightbox-container {
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  z-index: 100;
}
</style>
