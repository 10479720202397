<template>
  <div>
    <PageHeaderCard v-if="showHeader" :transparent="false" class="map-header">
    </PageHeaderCard>
    <v-container>
      <v-row v-if="showTitle">
        <v-col>
          <h1 class="pb-4">{{ pageData.title }}</h1>
        </v-col>
      </v-row>
      <StreamfieldBlock
        v-for="(block, i) in pageData.content"
        :key="i"
        :block="block"
      />
    </v-container>

    <v-dialog
      v-if="hasAccessibleModal"
      :content-class="`accessibility-lightbox ${mobileBrowser}`"
      persistent
      scrollable
      v-model="accessibilityModalFlag"
      ref="accessibilityLightbox"
    >
      <template v-slot:default="dialog">
        <v-card height="100%" data-testid="webpagelegacy-accessibility-modal">
          <FocusLoop :is-visible="activeTrap">
            <v-card-title class="text-h5 accessibility-header lighten-2">
              Accessibility Mode
            </v-card-title>
            <v-tabs show-arrows>
              <v-tab>Accessibility Mode</v-tab>
              <v-tab-item>
                <div class="pa-5">
                You have enabled Accessibility Mode, this mode enables
                additional accessibility features throughout the site including:
                <ul>
                  <li>Text Alternatives</li>

                  <li>Audio Descriptions</li>

                  <li>Alternative Navigation/interactivity Options</li>
                </ul>
                </div>
              </v-tab-item>
              <v-tab v-if="!$store.state.wagtail.siteSettings.hide_menu_mode">Site Navigation </v-tab>
              <v-tab-item >
                <v-list-item
                  v-for="(menuItem, i) in siteStructure"
                  :key="i"
                  :to="menuItem.pathname"
                  link
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon>{{ menuItem.menu_icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                </v-list-item></v-tab-item
              >
            </v-tabs>
            <v-divider></v-divider>
            <v-btn
              class="accessibility-btn close-accessibility-btn"
              @click="closeAccessibilityLightbox(), (dialog.value = false)"
              >Close</v-btn
            >
          </FocusLoop>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import StreamfieldBlock from "../../components/blocks/streamfieldBlock.vue";
import PageHeaderCard from "../../components/page/pageHeaderCard.vue";

export default {
  name: "WebPageLegacy",
  props: {
    pageData: Object,
    pageChildren: Array,
    showTitle: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    hasAccessibleModal: { type: Boolean, default: true },
  },
  components: { StreamfieldBlock: StreamfieldBlock, PageHeaderCard },
  data: () => ({
    mobileBrowser: "",
    showLanding: Boolean,
    accessibilityModalFlag: false,
    accessibilityFlag: false,
    accesibilityModal: false,
    activeTrap: true,
  }),
  mounted() {
    this.accessibilityFlagChecker();
    // if (this.pageData.landing_streamfield.length >= 1 ){
    //   this.$refs.landing.welcomeOpen();
    // }else this.$refs.landing.welcomeClosed();
    console.log(this.pageData, this.showLanding, "page data from webpage");
    this.mobileBrowser = /Mobi|Android/i.test(navigator.userAgent)
      ? "mobile-browser"
      : "";
  },
  computed: {
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    islightboxActive() {
      if (this.$store.state.lightbox.lightboxObjects.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        item.depth = item.meta.html_url.split("/").length - 4;
        let url = new URL(item.meta.html_url);
        item.pathname = url.pathname;
        item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
        siteStructure.push(item);
      }

      return siteStructure;
    },
  },
  methods: {
    closeAccessibilityLightbox() {
      this.accessibilityFlag = false;
      this.accessibilityModalFlag = false;
      this.$store.commit(
        "wagtail/setAccessibiltyMode",
        this.accessibilityFlag,
        { root: true }
      );
      localStorage.setItem("accessibilityModeState", false);
    },
    accessibilityFlagChecker() {
      if (
        localStorage.getItem("accessibilityModeState") == "true" ||
        this.accessibilityModalFlag == true
      ) {
        this.accessibilityModalFlag = true;
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityModalFlag,
          { root: true }
        );
        console.log("ACCESS STATE TRUE");
      } else {
        this.accessibilityModalFlag = false;
        this.accessibilityFlag = false;
        console.log("ACCESS STATE FALSE");
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityModalFlag,
          { root: true }
        );
      }
    },
  },
  watch: {
    accessibilityMode(newValue) {
      if (newValue == true) {
        this.accessibilityModalFlag = true;
        this.accessibilityFlag = true;
        localStorage.setItem("accessibilityModeState", true);
      }
      // else {
      //   this.accessibilityModalFlag = false;
      //   this.accessibilityFlag = false;
      //   const elements = document.querySelectorAll("#hotspot-img");
      //   localStorage.setItem("accessibilityModeState", false);
      //   this.$nextTick(() => {
      //     elements[0].focus();
      //   });
      // }
    },
    // islightboxActive() {
    //   if (this.islightboxActive == false) {
    //     if (this.accessibilityMode == true) {
    //       this.accessibilityModalFlag = true;
    //     } else {
    //       this.accessibilityModalFlag = false;
    //     }
    //   } else {
    //     this.accesibilityModal = false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .accessibility-lightbox {
  min-width: 300px;
  width: 25vw; 
  
  .accessibility-header {
  background-color: black;
  color: white;
}
}
::v-deep .accessibility-lightbox.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 70% !important;
}

::v-deep .accessibility-lightbox.v-dialog:not(.v-dialog--fullscreen).mobile-browser {
  max-height: calc(70% - 50px) !important;
}

</style>