<template>
  <LightboxContentLayout
    :accessibilityText="data.accessibility_text"
    :ariaDescription="undefined"
    :captionText="data.caption"
    :accessibilityTextFlag="false"
  >
    <div :id="uniqueDivId" class="full-screen"></div>
  </LightboxContentLayout>
</template>
<script>
import { filesize } from "filesize";
import LightboxContentLayout from "./LightboxContentLayout.vue";

export default {
  name: "LightboxDocumentCard",
  data() {
    return {
      dialog: true,
      downloadLink: true,
      uniqueDivId: `adobe-dc-view-${this._uid}`, // Generate a unique ID using Vue instance's unique identifier (required for our lightbox galleries with multiple pdf embed instances)
    };
  },
  props: ["data"],
  components: { LightboxContentLayout },
  computed: {
    pdf() {
      console.log(this.data, "document data");
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    size() {
      return filesize(this.data.file_size);
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
  },
  methods: {
    openWindow(url, label) {
      console.log(label);
      window.open(url);
    },
    initAdobeDCView() {      
      
      var adobeDCView = new AdobeDC.View({
        clientId: process.env.VUE_APP_ADOBE_PDF_API_KEY, // Adobe API Key Avaliable in Prod Keyvault
        divId: this.uniqueDivId, // Use the unique ID for each instance
      });
      const previewConfig = {
        embedMode: "FULL_WINDOW",
        showDownloadPDF: false,
        showZoomControl: true,
        showAnnotationTools: false,
        showFullScreen: false,
      };

      var previewFilePromise = adobeDCView.previewFile(
        {
          content: {
            location: {
              url: this.pdf,
            },
          },
          metaData: {
            fileName: this.data.title,
          },
        },
        previewConfig
      );
      
      // Set Zoom
      previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then(apis => {
          apis.getZoomAPIs().getZoomLimits()
          .then((result) => {

            const minZoom = result.minZoom
            const maxZoom = result.maxZoom

            const scale = maxZoom - minZoom
            const zoom = scale * (Number(this.data.default_zoom) * 0.01)

              apis.getZoomAPIs().setZoomLevel(zoom)
              .then((result) => {})
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => console.log(error))
        })

      })
    },
    loadAdobeDCViewSDK() {
      const checkSDKReady = setInterval(() => {
        if (window.AdobeDC) {
          clearInterval(checkSDKReady);
          this.initAdobeDCView();
        }
      }, 100); // check every 100ms until ready

      if (!window.AdobeDC) {
        const script = document.createElement("script");
        script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
        document.head.appendChild(script);
      }
    },
  },
  mounted() {
    this.loadAdobeDCViewSDK();

  },
};
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}
</style>
