<template>
  <div>
    <div v-if="currentPage !== undefined && error === undefined">
      <div :key="currentPage.id">
        <EngageWalkthrough
          v-if="currentPage.meta.type === 'engage_virtualrooms.WalkthroughRoom'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
          :accessibilityStream="accessibilityStream"
          @closeAccessibility="accessibilityStream = false"
        />
        <EngagementRoom
          v-if="currentPage.meta.type === 'engage_virtualrooms.VirtualRoomPage'"
          :streamfieldShowing="showStreamfield"
          :pageData="currentPage"
          :pageChildren="currentChildren"
          :accessibilityStream="accessibilityStream"
          @showLanding="showLanding(currentPage.id, $event)"
          @closeAccessibility="accessibilityStream = false"
        />
        <Page
          v-if="currentPage.meta.type === 'wagtailcore.Page'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <WebPageLegacy
          v-if="currentPage.meta.type === 'engage_pages.WebPageLegacy'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <WebPage
          v-if="currentPage.meta.type === 'engage_pages.WebPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <MapPage
          v-if="currentPage.meta.type === 'engage_maps.MapPage'"
          :streamfieldShowing="showStreamfield"
          :pageData="currentPage"
          :pageChildren="currentChildren"
          @showLanding="showLanding(currentPage.id, $event)"
        />
        <MapVirtualTourPage
          v-if="currentPage.meta.type === 'engage_maps.MapVirtualTourPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
      </div>
      <!-- lANDING datra -->
      <!-- <LandingStreamfield
        :pageType="typeOfPage"
        ref="landing"
        :data="currentPage"
        :key="currentPage.id"
        v-if="showWalkthroughStreamfield"
        @closed="landingClosed"
      >
      </LandingStreamfield> -->
      <LandingStreamfield
        :pageType="typeOfPage"
        ref="landing"
        :data="currentPage"
        :key="currentPage.id"
        v-if="showStreamfield"
        @closed="landingClosed"
      >
      </LandingStreamfield>
    </div>
    <div v-else-if="error !== undefined">
      <Error404 v-if="error.response.status === 404"></Error404>
    </div>
    <LoadingScreen v-show="loading" />
  </div>
</template>

<script>
import Vue from "vue";

import Page from "./wagtail/Page.vue";
import EngagementRoom from "./wagtail/EngagementRoom.vue";
import WebPageLegacy from "./wagtail/WebPageLegacy.vue";
import EngageWalkthrough from "./wagtail/EngageWalkthrough.vue";
import MapPage from "./wagtail/MapPage.vue";
import WebPage from "./wagtail/WebPage.vue";
import Error404 from "./wagtail/Error404.vue";
import MapVirtualTourPage from "./wagtail/MapVirtualTourPage.vue";

import LoadingScreen from "../components/loadingScreen/LoadingScreen.vue";
import LandingStreamfield from "../components/misc/LandingStreamfield.vue";
import Cookies from "js-cookie";

export default {
  name: "WagtailPage",
  data: () => ({
    dialog: true,
    error: undefined,
    loading: false,
    accessibilityStream: false,
    landingStreamfieldsClosed: [],
    startingPanoData: null,
    streamfieldLoaded: Cookies.get("streamfieldLoaded") === "true",
  }),
  components: {
    Page,
    EngagementRoom,
    WebPageLegacy,
    MapPage,
    WebPage,
    Error404,
    LoadingScreen,
    LandingStreamfield,
    MapVirtualTourPage,
    EngageWalkthrough,
  },
  mounted() {
    // Load the page data for this page
    this.loadPage(this.$route.path);

    console.log(
      this.$store.state.wagtail.currentPage,
      this.showStreamfield,
      this.currentPanoPage,
      "current page data"
    );
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentPage() {
      return this.$store.state.wagtail.currentPage;
    },
    // currentPanoPage(){
    //   const panoData = this.$store.dispatch('wagtail/getPageById', currentPage.starting_pano.id);
    //   return panoData
    //  },
    currentPopup() {
      return this.$store.state.wagtail.currentPopup;
    },
    currentChildren() {
      return this.$store.state.wagtail.currentChildren;
    },
    showStreamfield() {
      if (this.streamfieldLoaded === true) {
        return false;
      }

      if (
        this.currentPage &&
        this.currentPage.landing_streamfield !== undefined &&
        this.currentPage.landing_streamfield.length > 0
      )
        if (!this.landingStreamfieldsClosed.includes(this.currentPage.id))
          if (
            localStorage.getItem("accessibilityModeState") == "false" ||
            this.accessibilityStream
          )
            // If there is actually streamfield data
            // And if this streamfield hasn't been closed before
            return true; // Show it
      return false;
    },
    // showWalkthroughStreamfield(){
    //   if(this.panorama)

    // },
    typeOfPage() {
      if (
        this.$store.state.wagtail.currentPage.meta.type == "engage_maps.MapPage"
      ) {
        return "map";
      } else if (
        this.$store.state.wagtail.currentPage.meta.type ==
        "engage_virtualrooms.VirtualRoomPage"
      ) {
        return "room";
      } else return "page";
    },
  },
  watch: {
    showStreamfield(value) {
      if (!value) {
        Cookies.set("streamfieldLoaded", true, { expires: 30 });
      } else {
        this.removeStreamfieldLoadedCookie();
      }
    },
    currentPath(newPath) {
      // If the path changes, we want to load new page data from the API
      console.log("Wagtail page path changed: ", newPath);
      // if (
      //   this.currentPage.meta.type !== "engage_virtualrooms.WalkthroughRoom" 
      // ) {
        this.loadPage(newPath);
      // }
      // brings back scrollbars
      var bodyElement = document.querySelector("body");
      var htmlElement = document.querySelector("html");
      bodyElement.classList.remove("body-scrollhide");
      htmlElement.classList.remove("html-scrollhide");
      bodyElement.classList.remove("body-scrollhider");
      bodyElement.classList.remove("body-scrollshow");
      // console.log(this.currentPage.meta.type, "wagtail page path changed");
      this.fetchStartingPanoData();
    },
  },
  methods: {
    removeStreamfieldLoadedCookie() {
      
      // Remove the cookie
      Cookies.remove("streamfieldLoaded");

      // Update reactive property to force re-computation of the computed prop
      this.streamfieldLoaded = false;
    },
    showLanding(pageId, custom) {
      this.removeStreamfieldLoadedCookie();
     
      this.landingStreamfieldsClosed = this.landingStreamfieldsClosed.filter(
        function (item) {
          return item !== pageId;
        }
      );

      if (custom === "read-welcome") {
        this.accessibilityStream = true;
      }
    },    
    async fetchStartingPanoData() {
      try {
        const result = await this.$store.dispatch(
          "wagtail/getPageById",
          currentPage.starting_pano.id
        );
        this.startingPanoData = result; // Store the result in the state property
        console.log(this.startingPanoData, "result of pano");
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    },
    landingClosed() {
      this.accessibilityStream && (this.accessibilityStream = false);
      this.landingStreamfieldsClosed.push(this.currentPage.id);
      console.log("Closed: ", this.landingStreamfieldsClosed);
      let html = document.querySelector("html");

      if (
        this.$store.state.wagtail.currentPage.meta.type == "engage_maps.MapPage"
      ) {
        // html.style.overflowY = 'scroll'
        html.style.overflowX = "hidden";
      } else if (
        this.$store.state.wagtail.currentPage.meta.type ==
        "engage_virtualrooms.VirtualRoomPage"
      ) {
        html.style.overflowY = "hidden";
        html.style.overflowX = "hidden";
      } else {
        html.style.overflowY = "auto";
        html.style.overflowX = "hidden";
      }
    },
    updatePageTitle(title) {
      Vue.nextTick(() => {
        document.title = title || "Engage";
      });
    },
    loadPage(path) {
      this.error = undefined;
      let self = this;

      if (this.currentPage !== undefined && this.currentPage !== null) {
        let targetPath = path;
        let currentPath = new URL(this.currentPage.meta.html_url).pathname;

        // Skip loading if we're just trying to load the current page
        if (targetPath === currentPath) {
          self.updatePageTitle(this.currentPage.title);
          return;
        }

        // If current page or popup is a virtual room, Iterate children and if we find that we're
        // requesting a child we skip dispatching a load as we already have the room loaded.

        if (
          this.currentPage.meta.type === "engage_virtualrooms.VirtualRoomPage"
        ) {
          if (
            this.currentPage.child_content !== undefined &&
            this.currentPage.child_content !== null
          ) {
            console.log("Checking child_content");
            for (let child of this.currentPage.child_content) {
              let childPath = new URL(child.html_url).pathname;

              if (childPath === targetPath) {
                let loggedIn = self.$store.state.auth.status.loggedIn;

                if (!child.restricted || loggedIn) {
                  self.updatePageTitle(child.title);
                  return;
                }
              }
            }
          }
          if (
            this.currentPage.panorama_child_content !== undefined &&
            this.currentPage.panorama_child_content !== null
          ) {
            console.log("Checking panorama_child_content");

            for (let panoramaChild of this.currentPage.panorama_child_content) {
              let panoramaChildPath = new URL(panoramaChild.html_url).pathname;

              if (panoramaChildPath === targetPath) {
                let loggedIn = self.$store.state.auth.status.loggedIn;

                if (!panoramaChild.restricted || loggedIn) {
                  self.updatePageTitle(panoramaChild.title);
                  return;
                }
              }
            }
          }
          this.fetchStartingPanoData();
        }

        if (
          this.currentPopup &&
          this.currentPopup.meta.type ===
            "engage_virtualrooms.PanoramaRoomPopup"
        ) {
          if (
            this.currentPopup.child_content !== undefined &&
            this.currentPopup.child_content !== null
          ) {
            for (let child of this.currentPopup.child_content) {
              let childPath = new URL(child.html_url).pathname;

              if (childPath === targetPath) {
                let loggedIn = self.$store.state.auth.status.loggedIn;

                if (!child.restricted || loggedIn) {
                  self.updatePageTitle(child.title);
                  return;
                }
              }
            }
          }

          if (
            this.currentPopup.panorama_child_content !== undefined &&
            this.currentPopup.panorama_child_content !== null
          ) {
            for (let panoramaChild of this.currentPopup
              .panorama_child_content) {
              let panoramaChildPath = new URL(panoramaChild.html_url).pathname;

              if (panoramaChildPath === targetPath) {
                let loggedIn = self.$store.state.auth.status.loggedIn;

                if (!panoramaChild.restricted || loggedIn) {
                  self.updatePageTitle(panoramaChild.title);
                  return;
                }
              }
            }
          }
        }
        // If the current page type is a Engage Walkthrough Room, don't make a request to get page
        if (
          this.currentPage.meta.type === "engage_virtualrooms.EngageWalkthrough"
        )
          return;
      }

      // Trigger the loading screen
      this.loading = true;

      this.$store.dispatch("wagtail/getPage", path).then(
        (response) => {
          self.loading = false;
          // If we encounter a redirect page, we push a new URL to the rounter
          if (response.meta.type === "engage_pages.RedirectPage") {
            if (response.target_page !== undefined) {
              var path = new URL(response.target_page).pathname;
              self.$router.push(path);
            }
          } else if (response.meta.type === "engage_pages.GlobalRedirectPage") {
            if (response.target_page !== undefined) {
              //if engage_page meta type is GlobalRedirectPage use the location.href func instead to do a hard redirect to an external URL
              if (
                response.ga_campaign_name &&
                response.ga_event_category &&
                response.ga_event_label
              ) {
                this.$gtag.event("GA Campaign Name", {
                  event_category: response.ga_campaign_name,
                  event_label: response.ga_event_label,
                  value: response.ga_event_category,
                });
                var path = response.target_page;
                window.location.href = response.target_page;
              } else {
                var path = response.target_page;
                window.location.href = response.target_page;
              }
            }
          }

          self.updatePageTitle(response.title);
        },
        (error) => {
          self.loading = false;

          if (
            error.response.status === 404 &&
            self.$store.state.auth.status.loggedIn
          ) {
            self.error = error;
          } else {
            self.error = undefined;
            self.$router.push("/login?redirect=" + window.location.pathname);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
