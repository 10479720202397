var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.nextMarker()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.prevMarker()}]}},[(_vm.pageData !== undefined)?_c('MapArc',{ref:"map",attrs:{"legendOpacity":_vm.pageData.legend_opacity,"showLegend":_vm.pageData.show_legend,"showLegendWidget":true,"basemapWidget":_vm.pageData.basemap_widget,"mapZoom":_vm.pageData.zoom,"cameraTilt":_vm.cameraTilt,"heading":_vm.heading,"mapMinZoom":_vm.pageData.minZoom,"mapMaxZoom":_vm.pageData.maxZoom,"mapCenter":[_vm.pageData.lng, _vm.pageData.lat],"bottomLeftExtent":_vm.pageData.bottomLeftpoint,"topRightExtent":_vm.pageData.topRightpoint,"isRotationEnabled":_vm.pageData.disableMapRotation,"portalItemId":_vm.portalItemId,"portalItemType":_vm.portalItemType,"filterJson":_vm.$store.state.map.layerFilter,"layerOptions":_vm.layerOptions,"tokens":_vm.pageData.tokens,"selectedLayers":_vm.selectedLayers,"renderLayer":_vm.renderLayer,"renderView2d":_vm.renderView2d,"tabindex":"-1"},on:{"ready":function($event){return _vm.mapReady()},"mapLoadStart":function($event){_vm.loading = true},"mapLoadEnd":function($event){_vm.loading = false},"mapMoved":_vm.mapMoved,"mapZoomed":_vm.mapZoomed,"resetTilt":function($event){_vm.cameraTilt = $event}}},_vm._l((_vm.pageData.children),function(item,i){return _c('div',{key:_vm.pageData.id + 'page_' + i,on:{"click":function($event){return _vm.markerClicked(item)}}},[(
          (item.type == 'mappage' && item.showMarker) ||
          item.type == 'precinctlocation' ||
          (item.type !== 'mappage' && item.lat !== null && item.lng !== null)
        )?_c('MapMarkerArcHtml',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.showMarkers &&
          item.min_marker_zoom <= _vm.currZoomLevel &&
          item.max_marker_zoom >= _vm.currZoomLevel
        ),expression:"\n          showMarkers &&\n          item.min_marker_zoom <= currZoomLevel &&\n          item.max_marker_zoom >= currZoomLevel\n        "}],attrs:{"id":i,"coordinates":[item.lng, item.lat],"title":item.title,"icon":item.menu_icon,"mapCenter":_vm.mapCenter,"mapArc":_vm.$refs.map,"arialabel":_vm.getAriaLabel(item),"currZoomLevel":_vm.currZoomLevel,"markerType":item.type,"layerData":{ lat: item.lat, lng: item.lng },"hotspotGroupData":item.hotspotGroup,"thumbnailImage":item.thumbnail_image},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.nextMarker()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.prevMarker()}]}}):_vm._e()],1)}),0):_vm._e(),(this.currStickyItem != undefined && _vm.colsBreakpoint !== false && !_vm.mapLightbox)?_c('MapStickyCard',{attrs:{"stickyItemData":this.currStickyItem,"data-testid":"map-sticky-card-container"}}):_vm._e(),_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading"}),_c('LightboxGallery',{attrs:{"data-testid":"map-lightbox","showLightbox":_vm.lightboxContent.length > 0 && _vm.lightboxReady,"streamfieldShowing":_vm.streamfieldShowing,"content":_vm.lightboxContent},on:{"closed":function($event){return _vm.closeLightbox()}}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }