<template>
  <v-container fill-height fluid>
    <LoadingScreen v-if="loading"/>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-row justify="center" class="pl-6 pr-6">
          <v-scale-transition>
            <v-alert v-show="!loading && message !== ''" border="top" colored-border type="error" elevation="2">
              {{ message }}
            </v-alert>
          </v-scale-transition>
          <v-card width="100%">
            <v-toolbar :dark="primaryIsDark ? true : false" color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>

            <v-container class="pa-7">
              <v-row justify="center">
                <v-text-field v-model="user.username" label="Username" placeholder="Username" id="username" required
                  outlined dense prepend-icon="mdi-account" :color="primaryIsDark ? 'primary' : 'grey'">
                </v-text-field>
              </v-row>
              <v-row justify="center">
                <v-text-field id="password" v-model="user.password" label="Password" placeholder="Password" required
                  outlined dense prepend-icon="mdi-lock" type="password" :color="primaryIsDark ? 'primary' : 'grey'">
                </v-text-field>
              </v-row>
              <v-row justify="center">
                <v-btn id="login-btn" color="primary" :class="primaryIsDark ? 'white--text' : 'black--text'"
                  @click="handleLogin">
                  Login
                </v-btn>
              </v-row>
              <v-row justify="center" class="mt-5">
                <v-btn color="primary" @click="handleLoginMicrosoft"
                  :class="primaryIsDark ? 'white--text' : 'black--text'"> Login With SSO </v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingScreen from "../components/loadingScreen/LoadingScreen.vue";
import { isDark } from "../util/color"

export default {
  name: "Login",
  data: () => ({
    user: { username: "", password: "" },
    loading: false,
    message: "",
    primaryIsDark: false,
  }),
  components: {
    LoadingScreen
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let message = urlParams.get("message");
    if (message !== undefined && message != null) this.message = message

    const code = urlParams.get("code")
    if (code) {
      this.loading = true;
      this.$store.dispatch("auth/loginMicrosoft", code)
      .then(
        () => {
          this.$router.push("/");
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error)
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.detail) ||
            error.message ||
            error.toString();
        })
    }

    this.primaryIsDark = isDark(this.$vuetify.theme.currentTheme.primary)

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    primaryColor() {
      return this.$vuetify.theme.currentTheme.primary
    }
  },
  watch: {
    primaryColor(value) {
      // On primary color change, determine whether it's light or dark
      this.primaryIsDark = isDark(value)   
    }
  },
  methods: {
    handleLoginMicrosoft() {
      this.loading = true
      this.$store.dispatch("auth/loginMicrosoft")
    },
    handleLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectPath = urlParams.get("redirect");
            if (redirectPath !== null) this.$router.push(redirectPath);
            else this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.detail) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.login-btn {
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>